
import { Options, prop, Vue } from 'vue-class-component'
import Game from '@/scripts/rules'

class Props {
  game: Game = prop({ required: true })
}

@Options({})
export default class EchecGame extends Vue.with(Props) {
  selectedPawn: [number, number] | null = null

  clickSomewhere () {
    this.selectedPawn = null
  }

  selectPawn (pawn: [number, number]) {
    this.selectedPawn = pawn
  }

  movePawn (pawn: [number, number], to: [number, number]) {
    if (!pawn) return
    const success = this.game.movePawn(pawn, to)
    if (success) this.selectedPawn = null
  }

  placePawn (to: [number, number]) {
    const success = this.game.placePawn(to)
    if (success) this.selectedPawn = null
  }

  get movingOptions () {
    if (!this.selectedPawn) return []
    return this.game.wherePawnCanMove(this.selectedPawn)
  }
}
