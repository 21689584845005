
import { Options, Vue } from 'vue-class-component'
import EchecGame from '@/components/EchecGame.vue'
import Game from '@/scripts/rules'
import game from '@/maps/chessence'

@Options({ components: { EchecGame } })
export default class App extends Vue {
  game = new Game(game.map, game.pawnCount)
}
