
export const NOBODY = -1
// export const PLAYER_1 = 0
// export const PLAYER_2 = 1

export const EMPTY_TERRAIN = 0
export const TERRAIN = 1
// export const FORTRESS_1 = 2
// export const FORTRESS_2 = 3

export const NO_PAWN = -1
// export const PAWN_1 = 0
// export const PAWN_2 = 1
